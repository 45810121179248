<template>
	<div class="wrapper">
		<div class="article-box">
			<el-card class="box-card" shadow="never">
				<div slot="header" class="clearfix">
					<span>最新动态</span>
				</div>
				<el-skeleton :loading="loading" animated :count="5">
					<el-carousel height="150px" :interval="4000" type="card" indicator-position="none">
						<el-carousel-item v-for="(item, index) in newdata" :key="index">
							<el-image :src="item.banner" @click="_goto(item)"></el-image>
						</el-carousel-item>
					</el-carousel>
				</el-skeleton>
			</el-card>
		</div>
		<div class="article-box">
			<el-card class="box-card" shadow="never">
				<div slot="header" class="clearfix">
					<span>大家都在逛</span>
				</div>
				<el-skeleton :loading="loading" animated :count="10">
				<ul class="article-list">
					<li class="article-item" v-for="(item, index) in topdata" :key="index" @click="_goto(item)">
						<el-image :src="item.banner"></el-image>
						<div class="introduction">
							<span>{{ item.introduction }}</span>
						</div>
						<div class="user-info">
							<span class="avatar"><el-avatar :src="item.author_avatar" icon="el-icon-user" :size="20" /></span>
							<span class="user-name">{{ item.author_name }}</span>
						</div>
					</li>
				</ul>
				</el-skeleton>
			</el-card>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { Skeleton, SkeletonItem, Carousel, Card, CarouselItem, Empty, Image, Avatar } from 'element-ui';

Vue.use(Skeleton).use(SkeletonItem).use(Card).use(Carousel).use(CarouselItem).use(Empty).use(Image).use(Avatar)

import {getGoodsArticle} from '@/api/pc'

export default {
	components: {},
	data () {
		return {
			loading: true,
			newdata: [],
			topdata: []
		}
	},
	computed: {},
	created () {},
	mounted () {
		this._loadData()
	},
	methods: {
		_loadData() {
			getGoodsArticle().then(res => {
				this.loading = false
				if(res.errCode === 0) {
					this.newdata = res.data.newdata
					this.topdata = res.data.topdata
				}
			})
		},
		_goto(article) {
			this.$router.push({name: "GoodsArticleItem", query: {id: article.id}})
		}
	}
}
</script>

<style scoped>
.article-box {
	margin-top: 10px;
}

.article-list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.article-item {
	width: 310px;
	padding: 10px;
	cursor: pointer;
}

.introduction {
	height: 100px;
	padding: 10px;
	line-height: 24px;
	font-size: 13px;
	color: #666;
	overflow: hidden;
}

.user-info {
	display: flex;
	align-items: center;
}
.user-name {
	margin-left: 10px;
	font-size: 13px;
	color: #888;
}
</style>
